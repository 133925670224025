import Footer from "./Footer";
import Header from "./Header";
import { langAPI } from "../api/langAPI";
import LangContext from "../utils/LangContext";

import { useState, useEffect, useContext } from "react";
export default function Layout({ children }) {
  const [state, setState] = useState([]);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    langAPI(lang).then(setState);
  }, [lang]);

  return (
    <div className="layout">
      {state.map((item, idx) => (
        <Header key={idx} data={item} />
      ))}

      <main className="z-0 mt-6 mx-auto max-w-screen-lg">{children}</main>
      {state.map((item, idx) => (
        <Footer key={idx} data={item} />
      ))}
    </div>
  );
}
