import React, { useState, useEffect, useContext } from "react";
import LangContext from "../utils/LangContext";
import { langAPI } from "../api/langAPI";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

export default function IndexPage() {
  const [state, setState] = useState([]);
  const { lang } = useContext(LangContext);
  useEffect(() => {
    langAPI(lang).then(setState);
  }, [lang]);

  return state.map((item, idx) => <RenderIndex key={idx} data={item} />);
}

const RenderIndex = ({ data }) => {
  return (
    <>
      <h1>{data.body.h1}</h1>
      <section
        id="intro"
        className="md:container intro h-screen max-h-screen grid  grid-col-1 gap-4 md:grid-cols-4 md:grid-rows-3 place-content-center"
      >
        <div className="md:w-1/2 h-full grid w-48 col-1 md:col-start-3 md:col-span-4 md:row-start-1 pt-20 place-self-baseline md:justify-self-center justify-self-center">
          <img
            className="self-end"
            src={require("../images/ShirleyNeu.png")}
            alt="Shirley"
          />
        </div>

        <div className="lg flex flex-col gap-4 col-1 md:col-span-2 md:row-start-2 m-4">
          <h1 className="md:text-lg text-base font-display">
            {data.body.intro_h1}
          </h1>
          <h2 className="sm:text-xxl text-xl font-display w-max">
            {data.body.intro_h2}
          </h2>

          <p className="intro-position uppercase md:text-lg text-base">
            <span>{data.body.intro_position}</span>
          </p>
          <p>{data.body.about_lead}</p>
          <p>{data.body.about_p1}</p>
        </div>
        <div
          id="contact"
          className="flex md:flex-row flex-col gap-6 m-4 col-1 md:row-start-3 md:col-span-3 md:place-self-start"
        >
          <p>{data.footer.mail}</p>
          <a className="font-bold" href="mailto:shirley_lein@posteo.de">
            shirley_lein@posteo.de
          </a>
          <br />
          <p>{data.footer.phone}</p>
          <a className="font-bold" href="tel:+4915115881850">
            +49 1511 588 18 50
          </a>
        </div>
      </section>
      <section className="py-20 h-full " id="about">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 m-14">
          <AnimationOnScroll animateIn="animate__bounceIn">
            <div className="customColor text-center rounded-lg shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] h-48 mob:h-80 ">
              <h4 className="py-4">Frontend</h4>
              <p>
                <strong>HTML</strong>
              </p>
              <p>
                <strong>CSS/SASS</strong>
              </p>
              <p>
                <strong>JS/JQuery</strong>
              </p>
            </div>
          </AnimationOnScroll>

          <div className="flex h-40 w-full flex-row items-center justify-center rounded-md ">
            <div className="animate-shake duration-700 md:animate-none inline-block rounded-md p-1">
              <span className="  block rounded-md bg-white px-5 py-3 font-bold ">
                <h3 className="text-lg"> {data.body.skills}</h3>
              </span>
            </div>
          </div>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <div className=" customColor rounded-lg text-center shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] h-48 mob:h-80 ">
              <h4 className="py-4">Backend</h4>
              <p>
                <strong>MySQL</strong>
              </p>
              <p>
                <strong>Deno</strong>
              </p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <div className="customColor rounded-lg text-center shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] h-48 mob:h-80 ">
              <h4 className=" py-4">CMS</h4>
              <p>
                <strong>Wordpress</strong>
              </p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <div className="customColor rounded-lg text-center shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] h-48 mob:h-80 ">
              <h4 className="py-4">{data.body.technologies}</h4>
              <p>
                <strong>Git</strong>
              </p>
              <p>
                <strong>React JS</strong>
              </p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__bounceIn">
            <div className="customColor rounded-lg text-center shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset] h-48 mob:h-80 ">
              <h4 className="py-4">{data.body.interests}</h4>
              <p>
                <strong>Unity3D</strong>
              </p>
              <p>
                <strong>Blender</strong>
              </p>
              <p>
                <strong>Ai,Ps,Ae,Id,Xd</strong>
              </p>
            </div>
          </AnimationOnScroll>
        </div>
      </section>
      <section className="mt-18" id="projects">
        <div className="flex flex-col m-auto text-center mb-12 md:w-30 px-6">
          <h2 className="md:text-xxl mb-10 text-lg ">{data.body.work}</h2>

          <p>{data.body.work_p}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="cursor-pointer p-2 md:p-4 first:ml-0 shadow-lg min-h-80 rounded-lg place-self-center">
            <Link to="/project_1">
              <div className="relative w-60 md:w-64 overflow-hidden drop-shadow-sm h-48 md:h-90 hover:drop-shadow-md">
                <img
                  alt="Streifzug für Kinder 2023"
                  className="h-full w-full hover:scale-105 transition-all ease-out object-cover rounded-lg"
                  src={require("../images/project_1/streifzugLOGO.png")}
                />
              </div>
              <h3 className="mt-5 font-medium max-w-60">
                {data.body.project_title_3}
              </h3>
              <p className=" opacity-50 ">{data.body.sub_title_streif}</p>
            </Link>
          </div>
          <div className="cursor-pointer p-2 md:p-4 first:ml-0 shadow-lg min-h-80 rounded-lg place-self-center">
            <Link to="/project_2">
              <div className="relative w-60 md:w-64 overflow-hidden  drop-shadow-sm h-48 md:h-90 hover:drop-shadow-md">
                <img
                  alt="Soundlibrary Project"
                  className="h-full w-full hover:scale-105 transition-all ease-out object-cover rounded-lg"
                  src={require("../images/project_2/Upload-Sound Library1.png")}
                />
              </div>
              <h3 className="mt-5 font-medium max-w-60">
                {data.body.project_title_4}
              </h3>
              <p className="opacity-50 ">{data.body.sub_title_sound}</p>
            </Link>
          </div>
          <div className="cursor-pointer p-2 md:p-4 first:ml-0 shadow-lg min-h-80 rounded-lg place-self-center">
            <Link to="/project_3">
              <div className="relative w-60 md:w-64 overflow-hidden rounded-lg drop-shadow-sm h-48 md:h-90 hover:drop-shadow-md">
                <img
                  alt="Barcamp 2023"
                  className="h-full w-full hover:scale-105 transition-all ease-out object-cover rounded-lg"
                  src={require("../images/project_3/Barcamp00.png")}
                />
              </div>
              <h3 className="mt-5 font-medium max-w-60">
                {data.body.project_title_1}
              </h3>
              <p className=" opacity-50">{data.body.sub_title_barcamp}</p>
            </Link>
          </div>
          <div className="cursor-pointer p-2 md:p-4 first:ml-0 shadow-lg min-h-80 rounded-lg place-self-center">
            <Link to="/project_4">
              <div className="relative w-60 md:w-64 overflow-hidden rounded-lg drop-shadow-sm h-48 md:h-90 hover:drop-shadow-md">
                <img
                  alt="Kurzfilmtage 2023"
                  className="h-full w-full hover:scale-105 transition-all ease-out object-cover rounded-lg"
                  src={require("../images/project_4/KFT23-Publikum00.png")}
                />
              </div>
              <h3 className="mt-5 font-medium max-w-60">
                {data.body.project_title_2}
              </h3>
              <p className="opacity-50 max-w-40">{data.body.sub_title_kft}</p>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
