import React, { useState, useEffect, useContext } from "react";
import LangContext from "../utils/LangContext";
import { langAPI } from "../api/langAPI";
import ImageSlider from "../utils/ImageSlider";
export default function Barcamp() {
  const [state, setState] = useState([]);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    langAPI(lang).then(setState);
  }, [lang]);

  return state.map((item, idx) => <RenderBarcamp key={idx} data={item} />);
}

const SliderData = [
  {
    image: require("../images/project_3/Anfang-Barcamp23.png"),
  },
  {
    image: require("../images/project_3/Mitte-Barcamp23.png"),
  },
  {
    image: require("../images/project_3/Ende-Barcamp23.png"),
  },
  {
    image: require("../images/project_3/thankuBarcamp.png"),
  },
];

const RenderBarcamp = ({ data }) => {
  return (
    <>
      <div className="mt-36">
        <h1 className="md:px-40 px-4">Barcamp 2023</h1>
        <p className="md:px-40 px-4">{data.body.barcamp}</p>

        <ImageSlider slides={SliderData} />
      </div>
    </>
  );
};
