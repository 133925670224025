
import React, {useState,useLayoutEffect} from "react";

const LangContext = React.createContext({
    lang: '',
    switchLang: () => {},
  });
  export default LangContext;

export function LangProvider (props) {


  const [lang, setLang] = useState(window.localStorage.getItem('appUILang') || window.navigator.language);
  console.log("🚀 ~ file: LangContext.js:15 ~ LangProvider ~ lang:", lang)
  

  useLayoutEffect(() => {
    const selectedLang = window.localStorage.getItem('appUILang');
    console.log("🚀 ~ file: LangContext.js:20 ~ useLayoutEffect ~ selectedLang:", selectedLang)

    if (selectedLang) {
      setLang(selectedLang);
    } 
  }, [lang])

  const switchLang = (ln) => {
    setLang(ln);
    window.localStorage.setItem('appUILang', ln);
  };

  return (
    <LangContext.Provider value={{
      lang, 
      switchLang, 
      // currentLangData: langData[lang]
    }}>
      {props.children}
    </LangContext.Provider>
  );
};

// const langData = {
//   'en-US': {
//     label: {
//       name: 'Name',
   
//     },
//     app: {
//      p: 'Select Language'
//     },
//   },  app: {
//     h1: 'Select Language'
//   },
//   'de-DE': {
//     label: {
//       name: 'Name',
     
//     },
//     app: {
//       p: 'Wähle eine Sprache'
//     },
//   }
// }