import { Link } from "react-router-dom";

function Footer({ data }) {
  return (
    <>
      <footer id="contact" className="w-full grid place-content-center mt-16">
        <div className="footer p-8 ">
          <div className="footerContact">

            <div className="flex md:flex-row flex-col gap-6 mx-auto">
              <p>{data.footer.mail}</p>
              <a className="font-bold" href="mailto:shirley_lein@posteo.de">
                shirley_lein@posteo.de
              </a>
              <br />
              <p className="">{data.footer.phone}</p>
              <a className="font-bold" href="tel:+4915115881850">
                +49 1511 588 18 50
              </a>
            </div>
          </div>
          <div className="text-center ">
            <Link preventScrollReset={true} to="/impressum">
              {data.footer.imprint}
            </Link>
          </div>
          <div className="row text-center p-4">
            Copyright © 2024 Shirley Lein
          </div>
          <p className="text-sm text-center">{data.footer.contact}</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
