import { useContext } from "react";
// import "./LangSwitch.css";
import LangContext from "./LangContext";

export default function LangSwitch() {
  const { switchLang, lang } = useContext(LangContext);

  return (
    <div className="mt-auto flex flex-row">
      <div
        style={{
          opacity: lang === "en-US" ? 1 : 0.5,
        }}
        className="ring-2 md:ring-4 ring-inset border-solid border-2  rounded px-2 py-1"
      >
        <button onClick={() => switchLang("en-US")}>EN</button>
      </div>

      <div
        style={{
          opacity: lang === "de-DE" ? 1 : 0.5,
        }}
        className="ring-2 md:ring-4 ring-inset border-solid border-2  rounded px-2 py-1"
      >
        <button onClick={() => switchLang("de-DE")}>DE</button>
      </div>
    </div>
  );
}
