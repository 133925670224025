import { useState } from "react";
import LangSwitch from "../utils/LangSwitch";

import { Link } from "react-router-dom";
function Header(props) {
  const { data } = props;
  const [isNavOpen, setIsNavOpen] = useState(false); 

  return (
    <header className="">
      <div className="flex items-center justify-between  pt-8">
        <nav>
        {/* MOBILE */}
          <section className=" flex lg:hidden ">
            <div className={isNavOpen ? "hideMenuNav " : "showBurger"}>

              {/* HAMBURGER-ICON */}
              <div
                className=" bgwhite space-y-2 "
                onClick={() => setIsNavOpen((prev) => !prev)} 
              >
                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
              </div>
            </div>
            <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            {/* CROSS-ICON */}
              <div
                className=" absolute top-0 pl-16 pt-4 bgwhite"
                onClick={() => setIsNavOpen(false)} 
              >
                <svg
                  className="h-8 w-8 text-gray-600 "
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
              {/* MENU-LINK-MOBILE-OPEN */}
              <ul className=" bgwhite flex flex-col items-center justify-between min-h-[110px]">
                <li>
                  <LangSwitch />
                </li>
                <li className="border-b border-gray-400 my-8 uppercase ">
                  <Link
                    to="/"
                  >
                    {data.header.home}
                  </Link>
                </li>
              </ul>
            </div>
          </section>
          <div className="flex flex-row gap-3 pb-2">
          {/* DESKTOP-MENU  */}
            <ul className="hidden space-x-8 lg:flex">
              <li>
                <LangSwitch />
              </li>
              <li>
                <Link to="/">{data.header.home}</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
