export function langAPI(lang) {
  return new Promise((resolve) => resolve(data[lang]));
}

const data = {
  "en-US": [
    {
      header: {
        home: "Home",
      },
      body: {
        intro_h1: "Hello, World.",
        intro_h2: "I'm Shirley Lein.",
        intro_position: "Junior Web-Developer",

        // about_p1:
        //   "I am a junior web developer and I am currently studying media informatics at the Hochschule Flensburg. I am looking forward to new challenges and exciting projects.",
        about_lead:
          "I like getting to know new technologies and realizing exciting projects. This portfolio was created with React/Tailwind.",

        projects: "Profile",
        skills: "Skills",
        technologies: "Further Technologies",
        interests: "Further Interests",

        work: "Work",
        project_title_1: "Barcamp survey 2023",
        project_title_2: "Flensburg Short Film Festival 2023",
        project_title_3: "Stroll through Flensburg city center 2023",
        project_title_4:
          "Soundlibrary for the Flensburg University of Applied Sciences",
        kft: "An audience voting web application was created for the Flensburg Short Film Festival 2023. Frontend technologies are HTML/Nunjucks, SASS and Javascript and for the backend Deno and MySQL.",
        streif:
          "On June 9 and 10, 2023, the short film Streifzug durch die Flensburg city center took place. It was organized by the filmkorte e.V. and its cooperation partners. The survey was accessible via a QR code and was aimed at children. The graphics were created by Philip Drenckhahn created. The technologies used are: Deno, MySQL, JS, HTML, CSS.",
        barcamp:
          "As part of the filmkorte e.V. Barcamp 2023, a survey and competition was created as a web application. HTML/Nunjucks, SASS and Javascript were used for the frontend and Deno and MySQL were used for the backend.",

        sound:
          "Sound Library study project. The technology used for the web application is HTML/Nunjucks,SASS,Javascript in the frontend.",
        sub_title_streif: "Online competition for children",
        sub_title_sound: "Collaboration in the frontend",

        sub_title_kft: "Web app for online voting by the audience",

        sub_title_barcamp: "Online survey",
      },
      footer: {
        mail: "mail: ",
        phone: "phone: ",
        contact: "This portfolio was created with React/Tailwind.",
        imprint: "Imprint",
      },
    },
  ],
  "de-DE": [
    {
      header: {
        home: "Startseite",
      },
      body: {
        intro_h1: "Hallo, Welt da draussen.",
        intro_h2: "Ich bin Shirley Lein.",
        intro_position: "Junior Web-Entwicklerin",
        // intro_button: "Mehr über mich und meine Projekte",

        // about_p1:
        //   "Ich bin Junior-Webentwickler und studiere derzeit Medieninformatik an der Hochschule Flensburg. Ich freue mich auf neue Herausforderungen und spannende Projekte.",
        about_lead:
          "Ich mag es, neue Technologien kennenzulernen und spannende Projekte damit zu realisieren.",

        profile: "Profil",
        skills: "Fertigkeiten",
        technologies: "Weitere Technologien",
        interests: "Weitere Interessen",

        work: "Web Projekte",
        project_title_1: "Barcamp Umfrage 2023",
        project_title_2: "Flensburger Kurzfilmtage 2023",
        project_title_3: "Streifzug durch die Flensburger Innenstadt 2023",
        project_title_4: "Soundlibrary Projekt der Hochschule Flensburg",
        kft: "Für die Flensburger Kurzfilmtage 2023 wurde ein Publikums Abstimmungs Webanwendung erstellt. Technologien des Frontend sind HTML/Nunjucks, SASS und Javascript und für das Backend Deno und MySQL.",
        streif:
          "Am 9. und 10. Juni 2023 fand der Kurzfilm Streifzug durch die Flensburger Innenstadt statt. Veranstaltet wurde er von dem filmkorte e.V. und deren Kooperationspartner. Über einen QR-Code war die Umfrage erreichbar und richtete sich an Kinder. Die Grafiken wurden von Philip Drenckhahn erstellt. Die verwendetten Technologien sind: Deno, MySQL, JS, HTML, CSS.",
        barcamp:
          "Im Rahmen des Barcamps zum Thema Film 2023 des filmkorte e.V., wurde eine Umfrage und Gewinnspiel in Form einer Webanwendung erstellt. Für das Frontend wurde HTML/Nunjucks, SASS und Javascript und für das Backend Deno und MySQL verwendet.",

        sound:
          "Sound Library Studienprojekt Verwendete Technologie für die Webanwendung sind für Frontend HTML/Nunjucks,SASS,Javascript",

        sub_title_streif: "Onlinegewinnspiel für Kinder",
        sub_title_sound: "Mitarbeit im Frontend",

        sub_title_kft: "Web-App für Online-Abstimmungen durch das Publikum",

        sub_title_barcamp: "Onlineumfrage",
      },

      footer: {
        mail: "E-Mail: ",
        phone: "Telefon: ",
        contact: "Dieses Portfolio wurde mit React/Tailwind erstellt.",
        imprint: "Impressum",
      },
    },
  ],
};
