import React, { useCallback, useState } from "react";
// import { SliderData } from "./SliderData";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

import "react-medium-image-zoom/dist/styles.css";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const length = slides.length;
  const SliderData = slides;
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className="slider  flex flex-row p-4 ">
      
      <button type="button" className="pr-40">
        <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
      </button>

      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              // <ImageZoom src={slide.image} alt={slide.image}  zoom="200" />
              <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                  <img src={slide.image} alt={slide.image} className="image" />
                  </ControlledZoom>
            )}
          </div>
        );
      })}
      <button type="button" className="pl-40" onClick={nextSlide}>
        <FaArrowAltCircleRight className="right-arrow" />
      </button>
    </section>
  );
};

export default ImageSlider;
